import { useEffect, useState } from 'react';

import logo from '../assets/logo.png';
import stravaConnect from '../assets/strava_connect.svg';

const quotes = [
  '"It’s not the mountain we conquer, but ourselves.” – Sir Edmund Hillary',
  '"Every mountain top is within reach if you just keep climbing.” – Barry Finlay',
  '"The best view comes after the hardest climb.” – Unknown',
  '"Climb the mountains and get their good tidings." – John Muir',
  '"Mountains aren’t just funny, they are hill areas.” – Unknown',
  '"You are not in the mountains. The mountains are in you.” – John Muir',
  '"The mountains are calling and I must go.” – John Muir',
  '"Somewhere between the bottom of the climb and the summit is the answer to the mystery why we climb.” – Greg Child',
  '"Mountains have a way of dealing with overconfidence.” – Hermann Buhl',
  '"Only those who will risk going too far can possibly find out how far they can go.” – T.S. Eliot',
  '"Adventure is worthwhile in itself.” – Amelia Earhart',
  '"The hill we climb, if only we dare." – Amanda Gorman',
  '"The climb speaks to our character, but the view, I think, to our souls” – Lori Lansens',
  '"The world is not in your books and maps, it’s out there.” – J.R.R. Tolkien',
  '"I wanna see mountains again, Gandalf, mountains!” – J.R.R. Tolkien',
];

function LandingPage() {
  const [randomQuote, setRandomQuote] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);
  }, []);

  const redirectUri = import.meta.env.PROD
    ? 'https://api.summittale.com'
    : import.meta.env.VITE_API_URL;

  return (
    <div className="h-screen bg-gradient-to-br from-slate-700 to-slate-900 flex flex-col justify-center items-center text-center">
      <header className="flex flex-col mb-10 gap-3">
        <div className="flex items-center gap-4">
          <img src={logo} alt="Summit Tale logo" className="w-16 h-16" />
          <h1 className="text-5xl text-slate-100">Summit Tale</h1>
        </div>

        <p className="text-xl text-slate-200">
          Your personal peak journey, visualized.
        </p>
      </header>

      <main className="mb-8 px-6 md:px-0">
        <p className="text-slate-300 mb-4">
          Connect with Strava, sync your activities, and see all the peaks
          you&apos;ve visited on a map.
        </p>
        <p className="text-slate-300">
          Participate in or create lists like &quot;highest peaks in
          Europe&quot; or &quot;most prominent summits in Norway&quot;.
        </p>
      </main>

      <section className="italic m-4 mb-10 bg-slate-900 p-4 rounded-md shadow">
        <p className="text-slate-400">{randomQuote}</p>
      </section>

      <footer>
        <div className="flex justify-center items-center">
          <a
            href={`https://www.strava.com/oauth/authorize?client_id=91053&response_type=code&redirect_uri=${redirectUri}/strava_token_exchange&approval_prompt=auto&scope=profile:read_all,read,activity:read,activity:read_all,activity:write`}
          >
            <button type="button" className="shadow">
              <img src={stravaConnect} alt="Connect with strava" />
            </button>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
