import {
  type UseMutationResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import apiClient from '../../utils/apiClient';
import type { Ascent, Athlete, AthleteDTO } from '../../utils/apiTypes';

export const useGetMe = () =>
  useQuery({
    queryKey: ['me'],
    queryFn: () => apiClient.get<Athlete>('/athlete').then((res) => res.data),
  });

export const useGetAthletes = () =>
  useQuery({
    queryKey: ['athletes'],
    queryFn: () =>
      apiClient.get<Athlete[]>('/athletes').then((res) => res.data),
  });

export const useGetAthleteAscents = (athleteId: number) =>
  useQuery({
    queryKey: ['athleteAscents', athleteId],
    queryFn: () =>
      apiClient
        .get<Ascent[]>(`/athletes/${athleteId}/ascents`)
        .then((res) => res.data),
  });

export const useGetAthlete = (athleteId: number | undefined) =>
  useQuery({
    queryKey: ['athlete', athleteId],
    queryFn: () =>
      apiClient
        .get<AthleteDTO>(`/athletes/${athleteId}`)
        .then((res) => res.data),
    enabled: athleteId !== undefined,
  });

export const useRefreshAthlete = (): UseMutationResult<
  null,
  unknown,
  { athleteId: number }
> =>
  useMutation({
    mutationFn: ({ athleteId }) =>
      apiClient.post(`/athletes/${athleteId}/refresh`),
  });
