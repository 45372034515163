import { Suspense, lazy, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { APIContext } from '../apiProvider';
import ErrorFallback from '../components/errorFallback';
import LoadingSpinner from '../components/loadingSpinner';
import LandingPage from './landing';

// Define lazy-loaded components
const Mapplication = lazy(() => import('./mapplication'));

function App() {
  const { isSignedIn } = useContext(APIContext);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingSpinner />}>
        {!isSignedIn ? <LandingPage /> : <Mapplication />}
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
